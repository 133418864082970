import React, { useState } from 'react';
import Logo from "../../elements/logo/Logo";
import NavMobile from './NavMobile';
import { FiFacebook, FiInstagram, FiX } from "react-icons/fi";
import { FaWhatsapp, FaYoutube } from 'react-icons/fa';

const MobileMenu = ({ show, onClose }) => {
    // State to track dropdowns and megamenus
    const [openSubmenu, setOpenSubmenu] = useState(null);
    const [openMegamenu, setOpenMegamenu] = useState(null);

    const handleSubmenuToggle = (index) => {
        setOpenSubmenu(openSubmenu === index ? null : index);
    };
    const handleMegamenuToggle = (index) => {
        setOpenMegamenu(openMegamenu === index ? null : index);
    };

    return (
        <div className={`popup-mobile-menu ${show ? "active" : ""}`}>
            <div className="inner">
                <div className="header-top">
                    <Logo 
                        image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                        image2={`${process.env.PUBLIC_URL}/images/logo/logo-dark.png`}
                    />
                    <div className="close-menu">
                        <span className="close-button" onClick={onClose}><FiX /></span>
                    </div>
                </div>
                
                <NavMobile 
                    handleSubmenuToggle={handleSubmenuToggle} 
                    openSubmenu={openSubmenu}
                    handleMegamenuToggle={handleMegamenuToggle} 
                    openMegamenu={openMegamenu}
                />

                <div className="socials">
                    <h4>Get in Touch</h4>
                    <a href="mailto:info@codeaims.com" className='link-hover'>info@codeaims.com</a>
                    <p>TEL: <a href="tel:+923472201765">+(92) 347 2201765</a></p>
                    <div className="social-icon-wrapper">
                        <ul className="social-icon social-default transparent-with-border">
                            <li>
                                <a href="https://facebook.com/codeaims" target='_blank' rel="noreferrer">
                                    <FiFacebook />
                                </a>
                            </li>
                            <li>
                                <a href="https://instagram.com/codeaims" target='_blank' rel="noreferrer">
                                    <FiInstagram />
                                </a>
                            </li>
                            <li>
                                <a href="https://youtube.com/@Code_Aims" target='_blank' rel="noreferrer">
                                    <FaYoutube />
                                </a>
                            </li>
                            <li>
                                <a href="tel:+923472201765" target='_blank' rel="noreferrer">
                                    <FaWhatsapp />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobileMenu;