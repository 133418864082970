import React from 'react';
import { FiFacebook, FiInstagram, FiLinkedin } from "react-icons/fi";
import {Link} from "react-router-dom";

const CopyrightTwo = () => {
    return (
        <div className="copyright-area copyright-style-one">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-7 col-sm-12 col-12">
                        <div className="copyright-left">
                            <ul className="ft-menu link-hover">
                                <li><Link to="#">Privacy Policy</Link></li>
                                <li><Link to="#">Terms & Conditions</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-5 mt_sm--20">
                        <div className="copyright-center text-center">
                            <ul className="social-icon social-default color-lessdark justify-content-center">
                                <li><a href="https://facebook.com/codeaims" target='_blank' rel="noreferrer"><FiFacebook /></a></li>
                                <li><a href="https://instagram.com/codeaims" target='_blank' rel="noreferrer"><FiInstagram /></a></li>
                                <li><a href="https://www.linkedin.com/" target='_blank' rel="noreferrer"><FiLinkedin /></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12 mt_md--20 mt_sm--20">
                        <div className="copyright-right text-center text-lg-right">
                            <p className="copyright-text">
                                Creative and Inspiration with us.
                                Copyright © 2021 - {new Date().getFullYear()} <Link to="/"><strong>Code Aims</strong></Link>. All Rights Reserved.
                                </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CopyrightTwo;