import React from 'react';
import BlogListData from "../../../data/blog/BlogList.json";
import { LuMessageSquare } from 'react-icons/lu';

const SidebarArchive = () => {
    return (
        <ul>
            {BlogListData.map((value) => {
                return(
                    <li key={value.id}>
                        <LuMessageSquare className='mr--15' style={{fontSize: '20px'}} />
                        <span className="cate">{value.date}</span>
                    </li>
                )
            })}
        </ul>
    )
}
export default SidebarArchive
