import React from 'react'
import SEO from "../common/SEO";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import HeaderOne from '../common/header/HeaderOne';
import Copyright from '../common/footer/Copyright';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import CalltoActionTwo from '../elements/calltoaction/CalltoActionTwo'

import { BannerActivation } from "../utils/script";
import Slider from 'react-slick';
import VideoItem from "../elements/video/VideoItem";
import TestimonialOne from "../elements/testimonial/TestimonialOne";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
import CounterUpFour from '../elements/counterup/CounterUpFour';
import FooterTwo from '../common/footer/FooterTwo';
import AdvanceTabOne from '../elements/advancetab/AdvanceTabOne';
import ServiceThree from '../elements/service/ServiceThree';
import HeaderTopBar from '../common/header/HeaderTopBar';
import SliderEffect from '../elements/sliderEffect/SliderEffect';
var BlogListData = BlogClassicData.slice(0, 3);


const PopupData = [
    {
        id: "01",
        image: "/images/bg/video-landing-bg.jpg",
        popupLink: [
            'https://www.youtube.com/watch?v=WGR3aqSdgBc&t=67s',
        ],
    }
]

const BannerData = [
    {
        image: "/images/bg/landing-banner-1.jpg",
        title: "Your Digital Potential with Our Professional Web Development",
        description: "Our experienced web designers, UX/UI specialists, web developers, SEOs, digital marketers, manager specialize in business websites creation and more. We will deliver you marketing and technology solutions to achieve your company's goals in the best way.",
        button: "Free Consultation"
    },
    {
        image: "/images/bg/landing-banner-2.jpg",
        title: "Professional Web Development Agency.",
        description: "Our web developers are here to make it easier for you by providing their excellent services. Helping businesses stand out and succeed with effective digital solutions that drive high returns on investment.",
        button: "Let's Talk"
    },
]
    

const HomePage = () => {
    return (
        <>
            <SEO title="Web Desgin & Development" />
            <main className="page-wrapper">
                <HeaderTopBar HeaderSTyle="header-transparent"/>
                <HeaderOne  btnStyle="round btn-icon" HeaderSTyle="header-transparent pt--20" />

                {/* Start Slider Area  */}
                <Slider className="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow" {...BannerActivation}>
                    {BannerData.map((data, index) => (
                        <div key={index} className="single-slide">
                            <div className="height-950 bg-overlay bg_image" style={{backgroundImage: `url(${process.env.PUBLIC_URL} ${data.image})`}}>
                                <div className="container">
                                    <div className="row row--30 align-items-center">
                                        <div className="order-2 order-lg-1 col-lg-7">
                                            <div className="inner text-start">
                                                <h1 className="title" dangerouslySetInnerHTML={{__html: data.title}}></h1>
                                                <p className="description" dangerouslySetInnerHTML={{__html: data.description}}></p>
                                                <div className="button-group mt--30">
                                                    <a className="btn-default"  href="#contact" dangerouslySetInnerHTML={{__html: data.button}}></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
                {/* End Slider Area  */}
                
                {/* Start Counterup Area  */}
                <div className="our-counterup-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                {/* Start Elements Area  */}
                                <div className="rwt-counterup-area ptb--60">
                                    <div className="col-lg-12">
                                        <CounterUpFour column="col-lg-4 col-md-6 col-sm-6 col-12" counterStyle="counter-style-4" textALign="text-center" />   
                                    </div>
                                </div>
                                {/* End Elements Area  */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Counterup Area  */}

                <Separator />

                {/* Start Elements Area  */}
                <div className="rwt-advance-tab-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "What we Offer?"
                                    title = "SERVICES"
                                    description = ""
                                />
                            </div>
                        </div>
                        <AdvanceTabOne />
                    </div>
                </div>
                {/* End Elements Area  */}

                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area  rn-section-gap">
                    <div className="container">
                    <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "What We Can Do For You"
                                    title = "WE TURN YOUR WISHES INTO <br> BRILLIANT SOLUTIONS"
                                    description = "We offer a full suite of website development and programming <br> services, flexible pricing and superior service."
                                />
                            </div>
                        </div>
                        <ServiceThree
                            serviceStyle = "service__style--2"
                            textAlign = "text-center"
                            />
                    </div>
                </div>
                {/* End Service Area  */}

                <SectionTitle
                    textAlign = "text-center"
                    radiusRounded = ""
                    subtitle = "Latest Works"
                    title = "EXPLORE OUR WORKS"
                    description = ""
                />
                <SliderEffect />
                <div style={{textAlign: 'center'}} className=' pt--20 pb--100'>
                    <Link className="btn-default"  to="/portfolio"> View Works</Link>
                </div>

                
                <Separator />

                {/* Start Elements Area  */}
                <div className="rwt-testimonial-area rn-section-gap ">
                    <div className="container">
                        <div className="row mb--20">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Client Feedback"
                                    title = "Our Clients Feedback"
                                    description = ""
                                />
                            </div>
                        </div>
                        <TestimonialOne column="col-lg-4 col-md-6 col-12 mt--30" teamStyle="card-style-default testimonial-style-one" />
                    </div>
                </div>
                {/* End Elements Area  */}
                
                {/* <Separator /> */}

                {/* Start Video Area  */}
                <div className="rwt-video-area ">
                    <div className="container">
                        <div className="row">
                            {PopupData.map((item) => (
                                <div className="col-lg-12" key={item.id}>
                                    <VideoItem galleryItem={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Video Area  */}

                {/* Start Blog  */}
                <div className="blog-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Latests News "
                                    title = "Our Latest News"
                                    description = "From personal development tips to career advice, this blog <br> offers a wide range of topics that are sure to help everyone grow and succeed."
                                />
                            </div>
                        </div>
                        <div className="row row--15">
                            {BlogListData.map((item) => (
                                <div key={item.id} className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mt--30">
                                    <BlogList StyleVar="box-card-style-default" data={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div> 
                {/* End Blog */}

                {/* call action Start */}
                <CalltoActionTwo />
                {/* call action End */}

                {/* footer  */}
                <FooterTwo />
                <Copyright />                    
            </main>
        </>
    )
}
export default HomePage;

