import React from 'react';
import BlogClassicData from '../data/blog/BlogList.json';
import BlogDetailsContent from '../components/blog/BlogDetailsContent';
import SEO from "../common/SEO";
import Layout from "../common/Layout";

const BlogDetails = ({match: {params: {slug}}}) => {
    const data = BlogClassicData.find((post) => post.slug === slug);

    return (
        <>
            <SEO title={`${data.title} - Blogs`} />
            <Layout>
                <div className="rn-blog-details-area">
                    <BlogDetailsContent data={data[0]}  />
                </div>
            </Layout>
        </>
    )
}
export default BlogDetails;