import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from '../elements/separator/Separator';

import { FiArrowRight } from 'react-icons/fi';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import AdvanceService from '../elements/advancetab/AdvanceService';
import ContactForm from '../elements/contact/ContactForm';
import HeaderTopBar from '../common/header/HeaderTopBar';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import AccordionTwo from '../elements/accordion/AccordionTwo';
import ServiceMain from '../elements/service/ServiceMain';


const Services = () => {

    return (
        <>
            <SEO title="Our Services" />
                <main className="page-wrapper">
                    <HeaderTopBar HeaderSTyle="header-transparent"/>
                    <HeaderOne btnStyle=" round btn-icon" HeaderSTyle="header-transparent pt--20" />

                    <div className="slider-area slider-style-6 shape-right height-850">
                        <div className="container">
                            <div className="row row--30 align-items-center">
                                <div className="order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30">
                                    <div className="inner text-start">
                                        <h1 className="title color-white">WE TURN YOUR WISHES INTO BRILLIANT SOLUTIONS</h1>
                                        <p className="description color-white">We offer a full suite of website development and programming services, flexible pricing and superior service.</p>
                                        <div className="button-group mt--30 mt_sm--20">
                                            <Link className="btn-default btn-medium btn-icon btn-border" to="/contact">
                                                Free Consultation <i className="icon"><FiArrowRight /></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 order-1 order-lg-2">
                                    <div className="frame-image">
                                        <img src="./images/banner/banner-services.png" alt="Banner Images" />
                                    </div>
                                </div>
                                <div className="shape-image">
                                    <img src="./images/banner/white-shape.svg" alt="Banner Images" />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Start Service Area  */}
                    <div className="rn-service-area rn-section-gapTop" id='our-services'>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "What we can do for you"
                                        title = "Services provide for you."
                                        description = "There are many variations of passages of web development servicse <br /> but the majority have suffered alteration."
                                     />
                                </div>
                            </div>
                            <ServiceMain serviceStyle = "service__style--1 bg-color-blackest radius mt--25"
                                textAlign = "text-start" />
                        </div>
                    </div>
                    {/* End Service Area  */}

                    {/* Start Elements Area  */}
                    <div className="rwt-advance-tab-area rn-section-gapTop">
                        <div className="container">
                            <div className="row mb--40">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Our Past Works"
                                        title = "FEATURED WORKS"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <AdvanceService />
                        </div>
                    </div>
                    {/* End Elements Area  */}

                    <Separator />

                    {/* Start Accordion Area  */}
                     <div className="rn-accordion-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 offset-lg-1">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "FAQ"
                                        title = "Frequently Asked Questions"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <div className="row mt--35 row--20">
                                <AccordionTwo customStyle="rn-accordion-01" />
                            </div>
                        </div>
                    </div>
                    {/* End Accordion Area  */}

                    <Separator />

                    {/* Contact Form  */}
                    <div className="rwt-contact-area rn-section-gap" id='contact'>
                        <div className="container">
                            <div className="row mb--30">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Get In Touch"
                                        title = "WANT TO WORK WITH US?"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <div className="row mt--40 row--15">
                                <div className="col-lg-5 mt_md--30 mt_sm--30 pb--30">
                                    <img src={`/images/bg/contact-image.jpg`} alt={` Code Aims`} style={{width: '100%', maxHeight: '550px', height: '100%', objectFit: 'cover', borderRadius: '10px'}} />
                                </div>
                                <div className="col-lg-7">
                                    <ContactForm formStyle="contact-form-1" />
                                </div>
                            </div>
                        </div>
                    </div>  
                    {/* Contact Form  */}
 
                    <FooterTwo />
                    <Copyright />
                </main>

        </>
    )
}

export default Services;
