import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const AdvanceTabOne = () => {
    return (
        <div>
            <Tabs>
                <div className="row row--30">
                    <div className="order-1 order-lg-1 col-lg-4 col-md-12 col-sm-12 col-12 ">
                        <div className="advance-tab-button advance-tab-button-1">
                            <TabList className="tab-button-list">
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">WEB DEVELOPMENT SERVICES</h4>
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">DESIGN SERVICES</h4>
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">BUSINESS PACKAGES</h4>
                                    </div>   
                                </Tab>
                            </TabList>
                        </div>
                    </div>
                    <div className="order-2 order-lg-2 col-lg-8 col-md-12 col-sm-12 col-12 mt_md--30 mt_sm--30">
                        <div className="advance-tab-content advance-tab-content-1">
                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <p className='description'>HTML5 - CSS3 - JavaScript - Jquery - Bootstrap - Tailwind - PHP - MySQL - React</p>
                                        <ul>
                                            <li>
                                                <a href='/'>Website Development</a>
                                            </li>
                                            <li>
                                                <a href='/'>Coporate Website</a>
                                            </li>
                                            <li>
                                                <a href='/'>Startup Website</a>
                                            </li>
                                            <li>
                                                <a href='/'>Landing and Promo sites</a>
                                            </li>
                                            <li>
                                                <a href='/'>E-commerce</a>
                                            </li>
                                            <li>
                                                <a href='/'>WordPress Development</a>
                                            </li>
                                        </ul>
                                        <Link className="btn-default"  to="/contact">
                                            Read More
                                        </Link>
                                    </div>
                                </div>
                            </TabPanel>
                            
                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <p className='description'>Photoshop - Illustrator - Figma - Sketch</p>
                                        <ul>
                                            <li>
                                                <a href='/'>Web Design</a>
                                            </li>
                                            <li>
                                                <a href='/'>UX / UI Design</a>
                                            </li>
                                            <li>
                                                <a href='/'>Logo Design</a>
                                            </li>
                                            <li>
                                                <a href='/'>Branding Identity</a>
                                            </li>
                                            <li>
                                                <a href='/'>Banner Ad Design</a>
                                            </li>
                                        </ul>
                                        <Link className="btn-default"  to="/contact">Read More</Link>

                                        {/* <div className="thumbnail">
                                            <img src="./images/tab/tabs-02.jpg" alt="Corporate Images" />
                                        </div> */}
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <p className='description'>WordPress - Woocommerce - Bootstrap - Tailwind</p>
                                        <ul>
                                            <li>
                                                <a href='/'>Fixed Price Website</a>
                                            </li>
                                        </ul>
                                        <Link className="btn-default"  to="/contact">Read More</Link>
                                        {/* <div className="thumbnail">
                                            <img src="./images/tab/tabs-02.jpg" alt="Corporate Images" />
                                        </div> */}
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </Tabs>
        </div>
    )
}
export default AdvanceTabOne;