import React from 'react';
import { NavLink } from 'react-router-dom';

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li>
                <NavLink to="/" exact activeclassname="active">Home</NavLink>
            </li>
            <li>
                <NavLink to="/about-us" activeclassname="active">About</NavLink>
            </li>
            <li className="has-droupdown" activeclassname="active">
                <NavLink to="/services">Services</NavLink>
                <ul className="submenu">
                    <li>
                        <NavLink to="/web-development">Web Development</NavLink>
                    </li>
                </ul>
            </li>
            <li>
                <NavLink to="/portfolio" activeclassname="active">Portfolio</NavLink>
            </li>
            <li>
                <NavLink to="/blogs" activeclassname="active">Blog</NavLink>
            </li>
            <li>
                <NavLink to="/contact" activeclassname="active">Contact</NavLink>
            </li>
        </ul>
    )
}
export default Nav;
