import React from 'react';
import PortfolioDetailsContent from "../elements/portfolio/PortfolioDetailsContent";
import PortfolioData from "../data/portfolio/PortfolioData.json";
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


const PortfolioDetails = ({match: {params: {slug}}}) => {
    const data = PortfolioData.find(portfolio => portfolio.slug === slug);
    return (
        <>
            <SEO title={`${data.title} - Portfolio`} />
            <Layout>
                {data ? (
                    <PortfolioDetailsContent data={data} />
                ) : (
                    <div className="error-area ptb--200 ptb_sm--60 ptb_md--80">
                        <div className="container">
                            <div className="row align-item-center">
                                <div className="col-lg-12">
                                    <div className="error-inner">
                                        <h1>404</h1>
                                        <h2 className="title">Portfolio item not found.</h2>
                                        <p className="description"> It looks like nothing was found at this location.<br/> Maybe try to search for something else? </p>
                                        <div className="view-more-button">
                                            <Link className="btn-default" to="/portfolio">Go Back Portfolio</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Layout>
        </>
    )
}

export default PortfolioDetails;
