import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'

// Import Css Here 
import './assets/scss/style.scss';

// Pages import Here 
import HomePage from './pages/HomePage';
import AboutUs from "./pages/AboutUs";
import PersonalPortfolio from "./pages/PersonalPortfolio";
import Services from './pages/Services';
import Blogs from './pages/Blogs';
import WebDevelopment from "./pages/WebDevelopment";

import Registration from './pages/Registration';
import CategoryList from "./pages/CategoryList";
import TagList from "./pages/TagList";  
import Author from "./pages/Author";
import Portfolio from './pages/Portfolio';

// Elements import Here 
import Button from "./elements/button/Button";
import Progressbar from "./elements/progressbar/Progressbar";
import PortfolioDetails from "./pages/PortfolioDetails";
import Social from "./elements/social/Social";
import Timeline from "./elements/timeline/Timeline";
import Tab from "./elements/tab/Tab";
import Pricing from "./elements/pricing/Pricing";
import Split from "./elements/split/Split";
import Video from "./elements/video/Video";
import Gallery from "./elements/gallery/Gallery";
import AdvancePricing from "./elements/advancePricing/AdvancePricing";
import BlogDetails from "./pages/BlogDetails";
import Error from "./pages/Error";
import Contact from './pages/Contact';


const App = () => {
    return (
        <Router>
            <PageScrollTop>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={HomePage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/about-us"}`} exact component={AboutUs}/>
                    <Route path={`${process.env.PUBLIC_URL + "/services"}`} exact component={Services}/>
                    <Route path={`${process.env.PUBLIC_URL + "/contact"}`} exact component={Contact}/>
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio"}`} exact component={Portfolio}/>
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio/:slug"}`} exact component={PortfolioDetails}/>
                   
                    {/* Blog Part  */}
                    <Route path={`${process.env.PUBLIC_URL + "/blogs"}`} exact component={Blogs}/>
                    <Route path={`${process.env.PUBLIC_URL + "/blogs/:slug"}`} exact component={BlogDetails}/>
                    <Route path={`${process.env.PUBLIC_URL + "/category/:slug"}`} exact component={CategoryList}/>
                    <Route path={`${process.env.PUBLIC_URL + "/archive/:slug"}`} exact component={Author}/>
                    <Route path={`${process.env.PUBLIC_URL + "/tag/:slug"}`} exact component={TagList}/>

                    <Route path={`${process.env.PUBLIC_URL + "/web-development"}`} exact component={WebDevelopment}/>
                    <Route path={`${process.env.PUBLIC_URL + "/registration"}`} exact component={Registration}/>
                    <Route path={`${process.env.PUBLIC_URL + "/personal-portfolio"}`} exact component={PersonalPortfolio}/>

                    <Route path={`${process.env.PUBLIC_URL + "/button"}`} exact component={Button}/>
                    <Route path={`${process.env.PUBLIC_URL + "/progressbar"}`} exact component={Progressbar}/>
                    <Route path={`${process.env.PUBLIC_URL + "/social-share"}`} exact component={Social}/>
                    <Route path={`${process.env.PUBLIC_URL + "/timeline"}`} exact component={Timeline}/>
                    <Route path={`${process.env.PUBLIC_URL + "/tab"}`} exact component={Tab}/>
                    <Route path={`${process.env.PUBLIC_URL + "/pricing"}`} exact component={Pricing}/>
                    <Route path={`${process.env.PUBLIC_URL + "/split"}`} exact component={Split}/>
                    <Route path={`${process.env.PUBLIC_URL + "/video-popup"}`} exact component={Video}/>
                    <Route path={`${process.env.PUBLIC_URL + "/gallery"}`} exact component={Gallery}/>
                    <Route path={`${process.env.PUBLIC_URL + "/advance-pricing"}`} exact component={AdvancePricing}/>

                    <Route path="*" exact component={Error}/>
                </Switch>
            </PageScrollTop>
        </Router>
    )
}

export default App
