  import React, { useState, useEffect } from "react";
  import PortfolioData from "../../data/portfolio/PortfolioData.json";
  import PortfolioItem from "./PortfolioItem";


  const PortfolioRelative = ({ Column }) => {
      
    const [visibleItems, setVisibleItems] = useState([]);
    useEffect(() => {
      const shuffledItems = [...PortfolioData].sort(() => 0.5 - Math.random());
      setVisibleItems(shuffledItems.slice(0, 3));
    }, []);

    return (
      <>
        <div className="row row--15">
          {visibleItems.map((item) => (
            <div key={item.id} className={Column}>
              <PortfolioItem portfolio={item} />
            </div>
          ))}
        </div>
      </>
    )
  }

  export default PortfolioRelative;
