import React , {useState} from 'react';
import emailjs from 'emailjs-com';
import ReCAPTCHA from 'react-google-recaptcha';

const Result = () => {
    return (
        <p className="success-message">Your Message has been successfully sent. I will contact you soon.</p>
    )
}

function ContactForm({props , formStyle}) {
    const [ result, showresult ] = useState(false);
    const [recaptchaValue, setRecaptchaValue] = React.useState(null);    

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
        .sendForm(
            'service_fmu0o61',
            'template_ugdbc5h',
            e.target, 
            'SxlYvw80qTIhH-JyL'
        )
        .then((result) => {
            console.log(result.text);
            }, 
            (error) => {
                console.log(error.text);
            }
        );
        e.target.reset();
        showresult(true);

        if (!recaptchaValue) {
            alert("Please complete the reCAPTCHA");
            return;
        }
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

    return (
        <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
            <div className="form-group">
                <div className='form-field'>
                    <input 
                    type="text"
                    name="fullname"
                    id='fullname'
                    required
                    />
                    <label htmlFor='fullname'>Full Name <span>*</span></label>
                </div>
                <div className='form-field'>
                    <input 
                    type="text"
                    name="companyname"
                    id='companyname'
                    required
                    />
                    <label htmlFor='companyname'>Company Name <span>*</span></label>
                </div>
            </div>

             <div className="form-group">
                <div className='form-field'>
                    <input 
                    type="email"
                    name="email"
                    id='emailaddress'
                    required
                    />
                    <label htmlFor='emailaddress'>Email Address <span>*</span></label>
                </div>

                <div className='form-field'>
                    <input 
                    type="text"
                    name="phone"
                    id='phonenumber'
                    required
                    />
                    <label htmlFor='phonenumber'>Phone Number <span>*</span></label>
                </div>
            </div>

            <div className="form-group">
                <div className='form-field'>
                    <input 
                    type="text"
                    name="website"
                    id='website'
                    required
                    />
                    <label htmlFor='website'>Website <span>*</span></label>
                </div>

                <div className='form-field'>
                    <select name="budget" id='budget' aria-invalid="false" required>
                        <option value="budget" defaultValue disabled>Budget</option>
                        <option value="10K to 20K">10K to 20K</option>
                        <option value="20K to 30K">20K to 30K</option>
                        <option value="30K to 50K">30K to 50K</option>
                        <option value="100K">100K +</option>
                    </select>
                    <label htmlFor='budget'>Budget <span>*</span></label>
                </div>
            </div>

            <div className="form-group">
                <div className='form-field'>
                    <input 
                    type="text"
                    name="subject"
                    id='subject'
                    required
                    />
                    <label htmlFor='subject'>Subject <span>*</span></label>
                </div>
            </div>

            <div className="form-group">
                <div className='form-field form-field-textarea'>
                    <textarea 
                    name="message"
                    id='message'
                    required
                    >
                    </textarea>
                    <label htmlFor='message'>Your Message <span>*</span></label>
                </div>
            </div>

            <ReCAPTCHA
                sitekey="6LcC3nMqAAAAAFYvXnruvOyZiHkpiEVUyLiW0EnP"
                onChange={(value) => setRecaptchaValue(value)}
            />

            <div className="form-group mt--20">
                <button className="btn-default btn-large">Send Your Query</button>
            </div>  

            <div className="form-group">
                {result ? <Result /> : null}
            </div> 
        </form>
    )
}
export default ContactForm;
