import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        image: '/images/service/optimize-your-process.png',
        title: 'OPTIMIZE YOUR PROCESSES!',
        description: 'You will get a free personal manager who will help to build a strategy, optimize the budget and deadline, organize the development process and launch the project.'
    },
    {
        image: '/images/service/accelerate-your-revenue.png',
        title: 'ACCELERATE YOUR REVENUE!',
        description: '200+ years of cumulative team experience. No matter the industry, business goals, or location to turn your requirements into the profitable solution.'
    },
    {
        image: '/images/service/protect-your-business.png',
        title: 'PROTECT YOUR BUSINESS!',
        description: 'Every website or app is secure and built on the latest versions of open source content'
    },
    {
        image: '/images/service/generation-strategy.png',
        title: 'GET YOUR LEADS GENERATION STRATEGY!',
        description: 'Combining marketing and technology, we provide a full range of services from the UI/UX design and web development to SEO and promotion.'
    },
    {
        image: '/images/service/exceed-your-expectation.png',
        title: 'EXCEED YOUR EXPECTATIONS!',
        description: 'Not limited to standard methods. We use the horizontal a corporate culture that naturally encourages employees to be masters of innovations.'
    },
    {
        image: '/images/service/respect-you.png',
        title: 'RESPECT YOU!',
        description: 'It’s great to work with honesty, transparency, trustworthy and law-abiding.'
    }
]
const ServiceThree = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign} cursor-p `}>
                            <div className="inner">
                                <div className="image">
                                    <img src={`${val.image}`} alt="card Images" />
                                </div>
                                <div className="content">
                                    <h4 className="title" dangerouslySetInnerHTML={{__html: val.title}}></h4>
                                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceThree;