import React from 'react';
import Slider from "react-slick";
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import { BannerActivation } from "../utils/script";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import FooterTwo from '../common/footer/FooterTwo';
import { FiArrowRight } from 'react-icons/fi';
import ServiceWeb from '../elements/service/ServiceWeb';
import ServiceBenefits from '../elements/service/ServiceBenefits';
import AdvanceWeb from '../elements/advancetab/AdvanceWeb';
import VideoTwo from '../elements/video/VideoTwo';
import Copyright from '../common/footer/Copyright';
import HeaderTopBar from '../common/header/HeaderTopBar';
import InfiniteScrollAnimation from '../elements/scrollAnimation/InfiniteScrollAnimation';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import { EffectCoverflow  } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';


const TAGS = ['Business Websites', 'eCommerce Websites', 'Shopify', 'WordPress', 'Portfolio Wesbite', 'Agency Website', 'Web Development', 'Web Design', 'Responsive Design', 'Custom Web Design', 'User Experience', 'UX/UI', 'HTML5', 'CSS3', 'Graphic Design', 'Tech Solutions', 'Design To Code', 'Web Dev Tips', 'Developer Community', 'Landing Pages', 'Elementor', 'Animation', 'Tailwind', 'BootStrap', 'Restaurant Site', 'SEO'];

const slidesData = [
    { img: '/images/slider/codeaims-alex.jpg', alt: 'codeaims-alex' },
    { img: '/images/slider/codeaims-bocohstudios.jpg', alt: 'codeaims-bocohstudios' },
    { img: '/images/slider/codeaims-build.jpg', alt: 'codeaims-build' },
    { img: '/images/slider/codeaims-narjisvogue.jpg', alt: 'codeaims-narjisvogue' },
    { img: '/images/slider/codeaims-nftmarket.jpg', alt: 'codeaims-nftmarket' },
    { img: '/images/slider/codeaims-ppcpressure.jpg', alt: 'codeaims-ppcpressure' },
    { img: '/images/slider/codeaims-pressurewashing.jpg', alt: 'codeaims-pressurewashing' },
    { img: '/images/slider/codeaims-pro-cleaning.jpg', alt: 'codeaims-pro-cleaning' },
    { img: '/images/slider/codeaims-rezilla.jpg', alt: 'codeaims-rezilla' },
  ];

const BannerData = [
    {
        image: "/images/bg/about-banner.jpg",
        title: "Startup Your <br /> Business Agency.",
        description: "We help our clients succeed by creating brand identities, <br /> digital experiences, and print materials."
    },
    
]

const PopupData = [
    {
        id: "01",
        image: "./images/bg/bg-image-4.jpg",
        popupLink: [
            'https://www.youtube.com/watch?v=ctsT5Y-InqE&ab_channel=Rainbow-Themes',
        ],
    }
]


const WebDevelopment = () => {
    return (
        <>
            <SEO title="Desgin & Development Services" />
            <main className="page-wrapper">
                <HeaderTopBar HeaderSTyle="header-transparent"/>
                <HeaderOne btnStyle="btn-icon round" HeaderSTyle="header-transparent pt--20" />

                {/* Start Slider Area  */}
                <Slider className="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow" {...BannerActivation}>
                    {BannerData.map((data, index) => (
                        <div key={index} className="single-slide">

                            <div className="height-950 bg-overlay bg_image" style={{backgroundImage: `url(${process.env.PUBLIC_URL} ${data.image})`}}>
                                <div className="container">
                                    <div className="row row--30 align-items-center justify-content-end">

                                        <div className="order-2 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                                            <div className="inner text-start">
                                                <h1 className="title color-white">WEB DEVELOPMENT SERVICES</h1>
                                                <p className="description color-white">If you want to stand out in a competitive niche, get your business to the next level, and reach a larger audience, you might need a great web presence for your business. <br></br>
                                                Code Aims is a web development company that delivers custom web development solutions for businesses.
                                                </p>
                                                <div className="button-group mt--30 mt_sm--20">
                                                    <a className="btn-default btn-medium btn-icon btn-border" href="#contact">Contact Now <i className="icon"><FiArrowRight /></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 order-1 order-lg-2">
                                            {PopupData.map((item) => (
                                                <div className="video-btn" key={item.id}>
                                                    <VideoTwo imagename="./images/about/about-6.png" galleryItem={item} />
                                                </div>
                                            ))}
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
                {/* End Slider Area  */}

                <div className="rn-service-area rn-section-gapTop">
                    <InfiniteScrollAnimation TAGS={TAGS} />
                </div>
                
                <div style={{ width: '100%', margin: 'auto', padding: '50px 0' }}>
                    <Swiper
                        modules={[EffectCoverflow]}
                        effect="coverflow"
                        spaceBetween={0}
                        grabCursor={true}
                        slidesPerView={1.5}
                        centeredSlides={true}
                        loop={true}
                        coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: true,
                        }}
                        navigation
                        pagination={{ clickable: true }}
                        style={{ padding: '20px 0' }}
                        
                        breakpoints={{
                            640: {
                                slidesPerView: 1.5,
                                spaceBetween: 0,
                            },
                            768: {
                                slidesPerView: 1.8,
                                spaceBetween: 10,
                            },
                            1024: {
                                slidesPerView: 1.8,
                                spaceBetween: 30,
                            },
                        }}
                    >
                        {slidesData.map((slide, index) => (
                        <SwiperSlide key={index}>
                            <img
                            src={slide.img}
                            alt={slide.alt}
                            style={{ width: '100%', height: '100%' }}
                            />
                        </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

                {/* <Separator /> */}

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    title = "WEB DEVELOPMENT SERVICES"
                                    />
                            </div>
                        </div>
                        <ServiceWeb
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25"
                            textAlign = "text-center"
                        />
                    </div>
                </div>
                {/* End Service Area  */}


                {/* Start Elements Area  */}
                <div className="rwt-advance-tab-area rn-section-gap">
                        <div className="container">
                            <div className="row mb--40">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Web Services"
                                        title = "WEB DEVELOPMENT"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <AdvanceWeb />
                        </div>
                    </div>
                    {/* End Elements Area  */}

                

                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Key Benefits"
                                    title = "YOUR BENEFITS. OUR KEY EXPERTISE"
                                    description = ""
                                    />
                            </div>
                        </div>
                        <ServiceBenefits
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25"
                            textAlign = "text-center"
                        />
                    </div>
                </div>
                {/* End Service Area  */}

                <div className="rwt-callto-action-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <CalltoActionFive />
                            </div>
                        </div>
                    </div>
                </div>

                {/* footer  */}
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default WebDevelopment;
