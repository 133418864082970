import React, { useEffect } from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { FiArrowRight } from "react-icons/fi";
import { gsap } from 'gsap';
import SplitType from 'split-type';
import ScrollTrigger from "gsap/ScrollTrigger";

const callToActionData = {
    title: "Ready to start creating a standard website?",
    subtitle: "Finest choice for your home & office",
    btnText: "Purchase Code Aims",
}

gsap.registerPlugin(ScrollTrigger);

const CalltoActionFive = () => {
    useEffect(() => {
        const splitTextElements = document.querySelectorAll('.reveal-text');

        splitTextElements.forEach((el) => {
            const bgColor = el.dataset.bgColor;
            const fgColor = el.dataset.fgColor;
            const text = new SplitType(el, { types: 'chars' });

            gsap.fromTo(
                text.chars,
                {
                    color: bgColor,
                },
                {
                    color: fgColor, 
                    duration: 0.6,
                    stagger: 0.05,
                    scrollTrigger: {
                        trigger: el,
                        start: 'top 80%', 
                        end: 'top 20%',   
                        scrub: true,      
                        markers: false,   
                        toggleActions: 'play reverse play reverse', 
                    },
                }
            );
        });
        
    }, []);

    return (
        <div className="rn-callto-action clltoaction-style-default style-5">
            <div className="container">
                <div className="row row--0 align-items-center content-wrapper theme-shape">
                    <div className="col-lg-12">
                        <div className="inner">
                            <div className="content text-center">
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h2 className="title reveal-text" data-bg-color="#059DFF" data-fg-color="white">{callToActionData.title}</h2>
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h6 className="subtitle">{callToActionData.subtitle}</h6>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="call-to-btn text-center">
                                        <a className="btn-default btn-icon" href="/">{callToActionData.btnText} <i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionFive;