import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const tabData = [
    {
        title: "Personal",
        description: "Digital marketing efforts, instead of handling in-house. They can provide your business with a variety of digital solutions to promote your product or service online and help you hit your marketing goals and grow your business.",
        imgSrc: "/images/portfolio/personal.jpg",
        tabDescription: "Agency provides.",
    },
    {
        title: "Ibrahim Memon",
        description: "As a passionate software engineer, I thrive on the intricate dance between logic and creativity. Currently immersed in the dynamic world of WebHR, my expertise centers around React Native, where I seamlessly blend technology with innovation.",
        imgSrc: "/images/portfolio/ibrahim-memon.jpg",
        tabDescription: "Passionate Software.",
    },
    {
        title: "Chabook Services",
        description: "Context provides a way to share values between components without having to pass props through each level of the tree.",
        imgSrc: "/images/portfolio/chabook.jpg",
        tabDescription: "React application.",
    },
];

const AdvanceWeb = () => {
    return (
        <div>
            <Tabs>
                <div className="row row--30">
                    <div className="order-lg-1 order-2 col-lg-8 mt_md--30 mt_sm--30">
                        <div className="advance-tab-content advance-tab-content-1">
                            {tabData.map((data, index) => (
                                <TabPanel key={index}>
                                    <div className="tab-content">
                                        <div className="inner">
                                            <div className="thumbnail mb-5">
                                                <img style={{borderRadius: '8px'}} src={data.imgSrc} alt={data.title} />
                                            </div>
                                            <h4 className='title'>{data.title}</h4>
                                            <p className='description'>{data.description}</p>
                                            <Link className="btn-default" to="/portfolio">See Full Case</Link>
                                        </div>
                                    </div>
                                </TabPanel>
                            ))}
                        </div>
                    </div>
                    <div className="order-lg-2 order-1 col-lg-4 ">
                        <div className="advance-tab-button advance-tab-button-1 right-align">
                            <TabList className="tab-button-list">
                                {tabData.map((data, index) => (
                                    <Tab key={index}>
                                        <div className="tab-button">
                                            <h4 className="title">{data.title}</h4>
                                            <p className="description">{data.tabDescription}</p>
                                        </div>   
                                    </Tab>
                                ))}
                            </TabList>
                        </div>
                    </div>
                    
                </div>
            </Tabs>
        </div>
    )
}
export default AdvanceWeb;