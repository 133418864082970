import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


const SlpitOne = () => {
    return (
        <div className="rn-splite-style rn-section-gapBottom">
            <div className="split-wrapper">
                    <div className="row no-gutters radius-10 align-items-center">
                        <div className="col-lg-12 col-xl-6 col-12">
                            <div className="thumbnail image-left-content">
                                <img src="./images/split/split-01.jpg" alt="split Images" />
                            </div>
                        </div>
                        <div className="col-lg-12 col-xl-6 col-12">
                            <div className="split-inner">
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h2 className="title">Our Story</h2>
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                <p className="description">
                                    Code Aims is a reliable company specializing in web development, web design, and a variety of website-related services. Established by a group of passionate and skilled professionals. From day one, Code Aims has focused on transforming ideas into functional, aesthetic and outstanding offering businesses in the digital world.</p>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                <p className="description">
                                    The Code Aims’ success lies in its web development services. Whether creating a simple business website or a featured website, our team ensures every project ought to meet the need of client. We use the latest technologies and frameworks in order to make an extraordinary website. Every website is crafted with clean code, ensuring security, scalability, and optimal performance. Clients rely on Code Aims for seamless, reliable platforms that grow with their business, supporting everything from e-commerce and content management systems (CMS) to custom web applications designed to solve unique challenges.</p>
                                </ScrollAnimation>

                                
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <ul className="split-list">
                                        <li>- Doug DeMarco, Design Prototyping Manager</li>
                                        <li>- 108 million paying subscribers</li>
                                        <li>- Over 1.7 billion hours of music played monthly</li>
                                        <li>- 4,000+ employees working across 16 offices</li>
                                    </ul>
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="view-more-button mt--35">
                                        <Link className="btn-default" to="/contact">Contact With Us</Link>
                                    </div>
                                </ScrollAnimation>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SlpitOne
