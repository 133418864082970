import React from 'react';
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <FiActivity />,
        title: 'We are creative',
        description: 'We are masters in innovative, agile creative approaches in producing websites. Our refreshing way of working brings the best possible results for our clients. We deliver beautifully crafted projects with passion.'
    },
    
    {
        icon: <FiCast /> ,
        title: 'We use the latest technologies',
        description: 'We create highly-usable websites that are powered by an array of technologies and then we analyze performance data to optimize and refine.'
    },
    {
        icon: <FiMap />,
        title: 'Best Team',
        description: 'Our team provides personal, professional and friendly service which carefully considers your business goals and requirements. We transform innovative visions into brilliant realities. Got one? Let’s make outstanding work together!'
    },
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-xl-4 col-lg-6 col-md-12 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;