import React from 'react';
import FooterThree from './footer/FooterThree';
import NewsletterOne from './footer/NewsletterOne';

const BestLayout = ({children}) => {
    return (
        <>
            <main className="page-wrapper">
                {children}
                <NewsletterOne newsletetrStyle="rn-newsletter-default " extraClass="border-top-only" />
                <FooterThree />
            </main>
        </>
    )
}
export default BestLayout;
    