import React from 'react';
import SidebarTag from './sidebar/SidebarTag';
import Comment from './Comment';
import { slugify } from "../../utils";
import { Link, useParams } from "react-router-dom";
import { FiUser, FiCalendar } from "react-icons/fi";
import BlogClassicData from '../../data/blog/BlogList.json';
import BreadcrumbOne from '../../elements/breadcrumb/Breadcrumb';
import { FaClock, FaSignal } from "react-icons/fa6";
import SocialShareIcon from './sidebar/SocialShareIcon';
import SideCategories from './sidebar/SideCategories';
import SidebarPost from './sidebar/SidebarPost';
import SidebarArchive from './sidebar/SidebarArchive';
import BlogRelative from './BlogRelative';


const BlogDetailsContent = () => {

    const { slug } = useParams();
    const data = BlogClassicData.find((post) => post.slug === slug);

    if (!data) {
        return <>
            <div className="error-area ptb--200 ptb_sm--60 ptb_md--80">
                <div className="container">
                    <div className="row align-item-center">
                        <div className="col-lg-12">
                            <div className="error-inner">
                                <h1>404</h1>
                                <h2 className="title">Blog post not found.</h2>
                                <p className="description"> It looks like nothing was found at this location. <br /> Maybe try to search for something else? </p>
                                <div className="view-more-button">
                                    <Link className="btn-default" to="/blogs">Go Back Blogs</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    const shareUrl = window.location.href;
    
    return (
        <>
            <BreadcrumbOne
                rootUrl={`/`}  
                parentUrl={`Home`} 
                blogContentUrl={`Blog`}     
                blogUrl={`/blogs`} 
                currentUrl={data.title} 
            />
            <div className="post-page-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content text-center">
                                <div className='tagcloud mb--20'>
                                    {data.categories.map((category, index) => (
                                        <Link 
                                            key={index} 
                                            to={`/category/${slugify(category)}`}
                                        >
                                            {category}
                                        </Link>
                                    ))}
                                </div>
                                <div className="page-title">
                                    <h1 className="theme-gradient">{data.title}</h1>   
                                </div>
                                <ul className="rn-meta-list">
                                    <li><FiUser /><Link to={process.env.PUBLIC_URL + `/archive/${slugify(data.author)}`}>{data.author}</Link></li>
                                    <li><FiCalendar /> {data.date} </li>
                                    <li><FaClock />{data.reading} </li> 
                                    <li><FaSignal />{data.view} </li>   
                                </ul>
                                <div className="mt--20 text-center">
                                    <SocialShareIcon data={data} shareUrl={shareUrl} center={`flex-center`} />
                                </div>
                                
                                <div className="thumbnail alignwide mt--60">
                                    <img className="w-100 radius" src={`${process.env.PUBLIC_URL}/${data.largeImage}`} alt="Blog Images" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="blog-details-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 pr--40">
                            <div className="content">
                                {data.body.map((value, i) => {
                                    return(
                                        <div key={i} dangerouslySetInnerHTML={{__html: value}} />
                                    )
                                })}

                                <div className="category-meta">
                                    <span className="text">Tags:</span>
                                    <SidebarTag />
                                </div>

                                <div className="category-meta flex-column">
                                    <span className="text">Share This Post:</span>
                                    <SocialShareIcon data={data} shareUrl={shareUrl} round={`round`} />
                                </div>

                            </div>
                        </div>

                        <div className="col-lg-4 mt_md--40 mt_sm--40">
                            <aside className="rwt-sidebar">
                                {/* Start Single Widget  */}
                                <div className="rbt-single-widget widget_categories mt--40">
                                    <h3 className="title">Categories</h3>
                                    <div className="inner">
                                        <SideCategories />
                                    </div>
                                </div>
                                {/* End Single Widget  */}

                                {/* Start Single Widget  */}
                                <div className="rbt-single-widget widget_recent_entries mt--40">
                                    <h3 className="title">Recent Posts</h3>
                                    <div className="inner">
                                        <SidebarPost />
                                    </div>
                                </div>
                                {/* End Single Widget  */}

                                {/* Start Single Widget  */}
                                <div className="rbt-single-widget widget_archive mt--40">
                                    <h3 className="title">Archives</h3>
                                    <div className="inner">
                                        <SidebarArchive />
                                    </div>
                                </div>
                                {/* End Single Widget  */}
                            </aside>
                        </div>

                        <div className="col-lg-12 mt_md--40 mt_sm--40">
                            <BlogRelative />
                            <div className="rn-comment-form pt--60">
                                <div className="comment-respond">
                                    <h4 className="title mb--40">Leave a Reply</h4>
                                    <Comment 
                                        url=""
                                        id={data.id}
                                        title={data.title}
                                    />
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    )
}
export default BlogDetailsContent;
