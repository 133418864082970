import React, { useEffect } from 'react'
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import AccordionOne from '../elements/accordion/AccordionOne';
import SlpitOne from '../elements/split/SlpitOne';
import InfiniteScrollAnimation from '../elements/scrollAnimation/InfiniteScrollAnimation';
import CalltoActionSix from '../elements/calltoaction/CalltoActionSix';
import HeaderTopBar from '../common/header/HeaderTopBar';
import Separator from '../elements/separator/Separator';
import { gsap } from 'gsap';
import SplitType from 'split-type';
import ScrollTrigger from "gsap/ScrollTrigger";

const TAGS = ['Business Websites', 'eCommerce Websites', 'Shopify', 'WordPress', 'Portfolio Wesbite', 'Agency Website', 'Web Development', 'Web Design', 'Responsive Design', 'Custom Web Design', 'User Experience', 'UX/UI', 'HTML5', 'CSS3', 'Graphic Design', 'Tech Solutions', 'Design To Code', 'Web Dev Tips', 'Developer Community', 'Landing Pages', 'Elementor', 'Animation', 'Tailwind', 'BootStrap', 'Restaurant Site', 'SEO'];

gsap.registerPlugin(ScrollTrigger);

const AboutUs = () => {
    useEffect(() => {
        const splitTextElements = document.querySelectorAll('.reveal-text');

        splitTextElements.forEach((el) => {
            const bgColor = el.dataset.bgColor;
            const fgColor = el.dataset.fgColor;
            const text = new SplitType(el, { types: 'chars' });

            gsap.fromTo(
                text.chars,
                {
                    color: bgColor,
                },
                {
                    color: fgColor, 
                    duration: 0.6,
                    stagger: 0.05,
                    scrollTrigger: {
                        trigger: el,
                        start: 'top 80%', 
                        end: 'top 20%',   
                        scrub: true,      
                        markers: false,   
                        toggleActions: 'play reverse play reverse', 
                    },
                }
            );
        });
        
    }, []);

    return (
        <>
            <SEO title="About Us" />
            <main className="page-wrapper">
                <HeaderTopBar HeaderSTyle="header-transparent"/>
                <HeaderOne btnStyle="round btn-icon" HeaderSTyle="header-transparent pt--20" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-650 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/about-banner-1.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">We solve your challenges</span></h3>
                                    </div>
                                    <h1 className="title display-one">We are a Corporate <br /> Business Agency.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                {/* Start Service Area  */}
                <div className="service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title reveal-text" data-bg-color="#363636" data-fg-color="white">We are a creative digital agency dedicated to building and elevating our company’s brands.</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">  
                                    What makes Code Aims truly stand out is its dedication to client satisfaction and its collaborative approach to every project. The team believes in clear communication, involving clients at every step of the process—from initial concept discussions to final launch. They see themselves not just as service providers but as partners in their clients' growth. Many businesses have benefited from this approach, with websites that not only meet their needs but exceed expectations.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}

                <InfiniteScrollAnimation TAGS={TAGS}/>

                {/* Start Timline Area  */}
                <div className="rwt-timeline-area rn-section-gap">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    title = "Our Working Process."
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className=" col-xl-10 col-lg-12 offset-xl-1 mt--50">
                                <TimelineTwo classVar="" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Timline Area  */}             

                {/* Start Elements Area  */}
                <SlpitOne />
                {/* End Elements Area  */}

                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "What we do"
                                    title = "Our Expertise"
                                    description = ""/>
                            </div>
                        </div>
                        <ServiceOne 
                            serviceStyle="service__style--1 bg-color-blackest radius mt--25 rbt-border"
                            textAlign="text-start"/>
                    </div>
                </div>
                {/* End Service Area  */}  

                <Separator/> 
                
                {/* Start Accordion Area  */}
                <div className="rn-accordion-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "FAQ"
                                    title = "Frequently Asked Questions"
                                    description = ""
                                />
                            </div>
                        </div>
                        <div className="row mt--35 row--20">
                            <AccordionOne customStyle="rn-accordion-01" />
                        </div>
                    </div>
                </div>
                {/* End Accordion Area  */}

                {/* call to action  */}
                <CalltoActionSix />

                {/* footer  */}
                <FooterTwo />
                <Copyright />   
            </main>
        </>
    )
}

export default AboutUs;
