import React, { useState, useEffect } from "react";
import BlogList from './BlogList';
import { FaSpinner } from "react-icons/fa";


const BlogProp = ({ column , StyleVarProp, blogs}) => {
    const [dataVisibleCount, setDataVisibleCount] = useState(6);
    const [dataIncrement] = useState(3);
    const [noMorePost, setNoMorePost] = useState(false);
    const [visibleItems, setVisibleItems] = useState([]);

    useEffect(() => {
        setVisibleItems(blogs.slice(0, dataVisibleCount));
    }, [blogs, dataVisibleCount]);

    const handleLoadmorebl = (e) => {
        e.preventDefault();
        let tempCount = dataVisibleCount + dataIncrement;
        if (tempCount >= blogs.length) {
            setNoMorePost(true);
        }
        setDataVisibleCount(tempCount);
        setVisibleItems(blogs.slice(0, tempCount));
    }
    
    return (
        <>
            <div className="col-lg-12">
                <div className="row row--15">
                    {visibleItems.map((item) => (
                        <div key={item.id} className={column}>
                            <BlogList StyleVar={StyleVarProp} data={item} />
                        </div>
                    ))}
                </div>
            </div>
            <div className="col-lg-12 text-center">
                <div className="rwt-load-more text-center mt--60">
                    <button
                        className="btn btn-default btn-icon"
                        onClick={handleLoadmorebl}
                        disabled={noMorePost ? "disabled" : null}
                    >
                        {noMorePost ? (
                        "No Post Here"
                        ) : (
                        <span>
                            View More Post 
                            <span className="icon">
                                <FaSpinner />
                            </span>
                        </span>
                        )}
                    </button>
                </div>
            </div>
        </>
    )
}

export default BlogProp;