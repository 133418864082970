import React, { useState } from 'react';
import {Link} from "react-router-dom";
import ScrollTop from "./ScrollTop";
import Logo from '../../elements/logo/Logo';
import { FiFacebook, FiInstagram, FiLinkedin } from "react-icons/fi";
import footerOne from "../../data/footer/footerOne.json";
import ScrollAnimation from 'react-animate-on-scroll';
import Separator from '../../elements/separator/Separator';

const footerIntemOne =  footerOne[1];
const footerIntemTwo =  footerOne[2];
const footerIntemFive =  footerOne[5];


const indexOneLink = (footerIntemOne.quicklink);
const indexTwoLink = (footerIntemTwo.quicklink);

const FooterTwo = () => {
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState("");

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const mailchimpUrl = "https://codeaims.us14.list-manage.com/subscribe/post?u=6972d373be25debafebebb821&amp;id=21c2300d75&amp;f_id=002b0ae3f0";

        
        const formData = new FormData();
        formData.append('EMAIL', email);

        fetch(mailchimpUrl, {
            method: 'POST',
            body: formData,
            mode: 'no-cors' 
        })
        .then(() => {
            setMessage('Thank you for subscribing!');
            setMessageType("success"); 
            setEmail('');
        })
        .catch(() => {
            setMessage('There was an issue with your subscription. Please try again.');
            setMessageType("error");
        });

        setTimeout(() => {
            setMessage("");
            setMessageType("");
        }, 5000);
    };

    return (
        <>
            <footer className="rn-footer footer-style-default variation-two">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            {/* Start Single Widget  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <div className="inner">
                                        <div className="content text-start">
                                            <ScrollAnimation 
                                                animateIn="fadeInUp"
                                                animateOut="fadeInOut"
                                                animateOnce={true}>
                                                <Logo
                                                    image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                                                    image2={`${process.env.PUBLIC_URL}/images/logo/logo-dark.png`}
                                                />
                                            </ScrollAnimation>
                                            <ScrollAnimation 
                                                animateIn="fadeInUp"
                                                animateOut="fadeInOut"
                                                animateOnce={true}>
                                                <p className='description pt--5'>
                                                    Code Aim’s WordPress design and development service offers a wide range of features to help businesses create custom Websites.
                                                </p>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Widget  */}

                            {/* Start Single Widget  */}
                            <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <h4 className="title">{footerIntemOne.title}</h4>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            {indexOneLink.map((data, index) => (
                                                <li key={index}><Link to={`${data.url}`}>{data.text}</Link></li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Widget  */}

                            {/* Start Single Widget  */}
                            <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <h4 className="title">{footerIntemTwo.title}</h4>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            {indexTwoLink.map((data, index) => (
                                                <li key={index}><Link to={`${data.url}`}>{data.text}</Link></li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Widget  */}

                            {/* Start Single Widget  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget ">
                                    <h4 className="title">{footerIntemFive.title}</h4>
                                    <div className="inner">
                                        <form onSubmit={handleFormSubmit} className="rn-newsletter mt_md--20 mt_sm--20" >
                                            <div className="form-group">
                                                <div className='form-field'>
                                                    <input 
                                                        type="email"
                                                        name="subscriber"
                                                        id='emailAddress'
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required
                                                    />
                                                    <label htmlFor='emailAddress'>Email Address <span>*</span></label>
                                                </div>
                                                
                                            </div>
                                            <div className="form-group">
                                                <button className="btn-default w-100" disabled={isSubmitting} >
                                                    {isSubmitting ? 'Submitting...' : 'Submit Now'}
                                                </button>
                                            </div>
                                        </form>
                                        {message && (
                                            <p
                                                className={`subscription-message mt--20 ${messageType}`}
                                                style={{
                                                    color: messageType === "success" ? "green" : "red",
                                                    fontWeight: "bold",
                                                    marginTop: "10px"
                                                }}
                                            >
                                                {message}
                                            </p>
                                        )}
                                        <ul className="social-icon social-default justify-content-start">
                                            <li><a href="https://facebook.com/codeaims" target='_blank' rel="noreferrer"><FiFacebook /></a></li>
                                            <li><a href="https://instagram.com/codeaims" target='_blank' rel="noreferrer"><FiInstagram /></a></li>
                                            <li><a href="https://www.linkedin.com/" target='_blank' rel="noreferrer"><FiLinkedin /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Widget  */}
                        </div>
                    </div>
                </div>
            </footer>

            <Separator  />
            <ScrollTop />
        </>
    )
}

export default FooterTwo;
