import React from 'react';
import HeaderOne from "./header/HeaderOne";
import FooterThree from './footer/FooterThree';
import NewsletterOne from './footer/NewsletterOne';
import HeaderTopBar from './header/HeaderTopBar';

const Layout = ({children}) => {
    return (
        <>
            <main className="page-wrapper">
                <HeaderTopBar  HeaderSTyle="header-transparent"/>
                <HeaderOne btnStyle="round btn-icon" HeaderSTyle="header-not-transparent pt--20 pb--20" />
                {children}
                <NewsletterOne newsletetrStyle="rn-newsletter-default " extraClass="border-top-only" />
                <FooterThree />
            </main>
        </>
    )
}
export default Layout;
