import React from 'react'
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import Separator from "../elements/separator/Separator";
import RegistrationNote from '../elements/registrationNote/RegistrationNote';
import BestLayout from '../common/BestLayout';

const Registration = () => {
    return (
        <>
            <SEO title="Web Desgin & Development Classes" />
            <BestLayout>
                <main className="page-wrapper">
                    <HeaderOne btnStyle="round btn-icon" HeaderSTyle="header-transparent pt--20" />

                    {/* Start Slider Area  */}
                    <div className="slider-area slider-style-1 height-650 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/about-banner.jpg)`}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="inner pt--80 text-center">
                                        <div>
                                            <h3 className="rn-sub-badge"><span className="theme-gradient">Register Now</span></h3>
                                        </div>
                                        <h1 className="title display-one">WEB DESGIN & <br /> DEVELOPMENT</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                    {/* End Slider Area  */}

                    <Separator />
                    
                    {/* Start Elements Area  */}
                    <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                            <RegistrationNote />
                        </div>
                    </div>
                    {/* End Elements Area  */}

                </main>
            </BestLayout>
        </>
    )
}

export default Registration;
