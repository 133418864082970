import React from 'react';
import { Link, useParams } from 'react-router-dom';
import BlogClassicData from '../../data/blog/BlogList.json';
import { slugify } from "../../utils";
import { FaLongArrowAltRight, FaLongArrowAltLeft  } from "react-icons/fa";

const BlogRelative = () => {
    const { slug } = useParams();

    // Find the index of the current post
    const currentIndex = BlogClassicData.findIndex((post) => post.slug === slug);

    // Find the next and previous posts
    const nextPost = currentIndex < BlogClassicData.length - 1 ? BlogClassicData[currentIndex + 1] : null;
    const prevPost = currentIndex > 0 ? BlogClassicData[currentIndex - 1] : null;

    return (
        <div className="blog-relative-nav">
            <div className="row">
                {/* Previous Post Link */}
                {prevPost && (
                    <Link to={`/blogs/${slugify(prevPost.slug)}`} className="col-lg-6 col-md-6 col-sm-12">
                        <div className="prev-post">
                            <h4> <FaLongArrowAltLeft /> Previous Post</h4>
                            <h5>{prevPost.title}</h5>
                        </div>
                    </Link>
                )}

                {/* Next Post Link */}
                {nextPost && (
                    <Link to={`/blogs/${slugify(nextPost.slug)}`} className="col-lg-6 col-md-6 col-sm-12 text-right">
                        <div className="next-post">
                            <h4> Next Post <FaLongArrowAltRight /> </h4>
                            <h5>{nextPost.title}</h5>
                        </div>
                    </Link>
                )}
            </div>
        </div>
    );
};

export default BlogRelative;
