import React from 'react';
import {NavLink} from "react-router-dom";

const NavMobile = () => {
    return (
        <ul className="mainmenu">
            <li className="resMenuItem">
                <NavLink to="/" className="MenuBul" exact activeClassName="active">
                    <span>01</span>
                    Home
                </NavLink>
            </li>
            <li className="resMenuItem">
                <NavLink to="/about-us" className="MenuBul" activeClassName="active">
                    <span>02</span>
                    About Us
                </NavLink>
            </li>
            <li className="resMenuItem">
                <NavLink to="/services" className="MenuBul" activeClassName="active">
                    <span>03</span>
                    Services
                </NavLink>
            </li>
            <li className="resMenuItem">
                <NavLink to="/web-development" className="MenuBul" activeClassName="active">
                    <span>04</span>
                    Web Development
                </NavLink>
            </li>
            <li className="resMenuItem">
                <NavLink to="/portfolio" className="MenuBul" activeClassName="active">
                    <span>05</span>
                    Our Works
                </NavLink>
            </li>
            <li className="resMenuItem">
                <NavLink to="/blogs" className="MenuBul" activeClassName="active">
                    <span>06</span>
                    Blogs
                </NavLink>
            </li>
            <li className="resMenuItem">
                <NavLink to="/contact" className="MenuBul" activeClassName="active">
                    <span>07</span>
                    Contact Us
                </NavLink>
            </li>
            <li className="resMenuItem">
                <NavLink to="/registration" className="MenuBul" activeClassName="active">
                    <span>08</span>
                    Classes
                </NavLink>
            </li>
        </ul>
    )
}
export default NavMobile;
