import PropTypes from "prop-types";
import React from 'react'


const SEO = ( {title} ) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>{title} || Code Aims</title>
            <meta name="robots" content="noindex, follow" />
            <meta name="description" content="Discover Code Aims. With experience of over a decade, we're experts in redefining your online presence with tailored end-to-end website & hosting ..." />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;


