import React from 'react';
import {Link} from "react-router-dom";

const BreadcrumbOne = ({rootUrl , parentUrl, blogContentUrl, blogUrl, currentUrl}) => {
    return (
        <div className="breadcrumb-area breadcrumb-style-1 ">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-inner text-center">
                            <ul className="page-list">
                                <li className="rn-breadcrumb-item"><Link to={`${rootUrl}`} dangerouslySetInnerHTML={{__html: parentUrl}}></Link></li>
                                <li className="rn-breadcrumb-item"><Link to={`${blogUrl}`} dangerouslySetInnerHTML={{__html: blogContentUrl}}></Link></li>
                                <li className="rn-breadcrumb-item active" dangerouslySetInnerHTML={{__html: currentUrl}}></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BreadcrumbOne;