import React from 'react';
import InfiniteLoopSlider from './InfiniteLoopSlider';
import Tag from './Tag';

const DURATION = 25000;
const ROWS = 2;
const TAGS_PER_ROW = 6;

const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
const shuffle = (arr) => [...arr].sort(() => 0.5 - Math.random());

const InfiniteScrollAnimation = ({TAGS}) => (
  <div className='animate-box'>
    <div className='tag-list'>
        {[...new Array(ROWS)].map((_, i) => (
            <InfiniteLoopSlider key={i} duration={random(DURATION - 5000, DURATION + 5000)} reverse={i % 2}>
            {shuffle(TAGS).slice(0, TAGS_PER_ROW).map(tag => (
                <Tag text={tag} key={tag}/>
            ))}
            </InfiniteLoopSlider>
        ))} 
      <div className='fade1' />
    </div>
  </div>
);

export default InfiniteScrollAnimation;