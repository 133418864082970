import React from 'react'

import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    WhatsappIcon,
    PinterestIcon,
    PinterestShareButton
} from "react-share";

const SocialShareIcon = ({shareUrl, data, round, center}) => {
  return (
    <div className={`social-share-buttons ${center}`}>
        <FacebookShareButton url={shareUrl} quote={data.title} className="social-share-button">
            <FacebookIcon size={50} round={round} />
        </FacebookShareButton>
        <PinterestShareButton url={shareUrl} quote={data.title} className="social-share-button">
            <PinterestIcon size={50} round={round} />
        </PinterestShareButton>
        <TwitterShareButton url={shareUrl} title={data.title} className="social-share-button">
            <TwitterIcon size={50} round={round} />
        </TwitterShareButton>
        <LinkedinShareButton url={shareUrl} title={data.title} summary={data.description} className="social-share-button">
            <LinkedinIcon size={50} round={round} />
        </LinkedinShareButton>
        <WhatsappShareButton url={shareUrl} title={data.title} separator=":: " className="social-share-button">
            <WhatsappIcon size={50} round={round} />
        </WhatsappShareButton>
    </div>
  )
}

export default SocialShareIcon