import React from 'react';
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <FiActivity />,
        title: 'Design',
        description: "<ol style='display: flex; flex-direction: column;'> <li> Web Design </li> <li> UX / UI Design </li> <li> Logo Design</li> <li> Branding Identity</li> <li> Banner Ad Design </li> </ol>"
    },
    {
        icon:  <FiCast />,
        title: 'Web Develoment',
        description: "<ol style='display: flex; flex-direction: column;'> <li> Website Development </li> <li> Coporate Website </li> <li> Startup Website </li> <li> Landing and Promo sites </li> <li> E-commerce </li> <li> WordPress Development </li> <li> Fixed Price Website </li> </ol>"
    },
    {
        icon: <FiMap />,
        title: 'Digital Marketing',
        description: "<ol style='display: flex; flex-direction: column;'> <li> Website marketing strategy </li> <li> Content marketing </li> <li> PPC services </li> <li> Social media marketing services </li> </ol>"
    },
]


const ServiceMain = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className=" col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceMain;